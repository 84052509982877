import { StaticImage } from "gatsby-plugin-image";
import { Link, useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import { AiOutlineCheck, AiOutlineLoading } from "react-icons/ai";
import apiJobs from "../../api/jobs";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";

interface IProps {}

const All = (props: IProps) => {
  const [loading, setLoading] = React.useState(false);
  const [jobs, setJobs] = React.useState(null);
  const [companyId, setCompanyId] = React.useState(null);
  const { formatMessage: fm } = useIntl();

  React.useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const res = await apiJobs.find(companyId);
      if (res.success) {
        setJobs(res.data);
      } else {
        console.log("RES:ERROR:  ", res.e);
      }
      setLoading(false);
    };
    fetch();
  }, []);
  React.useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const res = await apiJobs.findCompany();
      if (res.success) {
        setCompanyId(res.data);
      } else {
        console.log("RES:ERROR:  ", res.e);
      }
      setLoading(false);
    };
    fetch();
  }, []);

  console.log("jobs: ", jobs);
  console.log("CompanyID: ", companyId);

  return (
    <>
      <SEO title={fm({ id: "menu-contact" })} />
      <Layout inverse>
        <div>
          <div className="min-h-screen w-full overflow-hidden font-cool pt-36 bg-white">
            <div
              className="container p-4 relative mx-auto"
              data-sal="slide-up"
              data-dal-delay="1000"
              data-sal-duration={2000}
            >
              <div className="flex flex-col lg:flex-row items-center">
                <div className="w-full">
                  {jobs ? (
                    <>
                      <div className="w-full p-4">
                        <p className="text-base lg:text-2xl text-gray-300 font-body">
                          {fm({ id: "home-hr-sub-last" })}
                        </p>
                      </div>

                      {/* <div className=" border absolute  h-full right-0 w-full transform lg:translate-x-1/2"></div> */}

                      <div className="relative h-full">
                        {jobs
                          ?.filter((_, i) => {
                            return i;
                          })
                          .map((job) => (
                            <div className="p-5 border-b">
                              {/* <div className="mb-3 border-b border-black border-opacity-10 w-screen"></div> */}
                              <div className="w-full leading-none flex">
                                <h1 className="text-xl lg:text-3xl font-body text-black ">
                                  {job?.name}
                                </h1>

                                <Link
                                  to="/hr/apply"
                                  className="bg-main flex items-center justify-center text-white h-6 lg:h-10 ml-auto mt-2 rounded-none w-18 md:w-36"
                                >
                                  {fm({ id: "home-hr-sub-anket" })}
                                </Link>
                              </div>
                              {/* <div className="my-3 border-b border-black border-opacity-10 w-screen"></div> */}
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-center">
                      <span className="text-xl">
                        <AiOutlineLoading
                          color="#a98b69"
                          className="animate-spin"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default All;
